import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Backlink from "../components/back-link"


const NotFoundPage = () => (
  <Layout>
    <SEO title="Ins dunkle getappt" />
    <div className="grid grid-cols-12 px-4 lg:px-6 min-h-screen">
    <div className="col-span-12 mt-16 mb-16">
    <h1>Ins Dunkle getappt</h1>
    <p className="pl-0">Hier ist es ganz schön dunkel...
    </p>
    <Backlink />
    </div>
    </div>
  </Layout>
)

export default NotFoundPage
